import React, { Component } from "react";

export default class PlayerCard extends Component {
  render() {
    return (
      <p className="lead">
        {this.props.stats[1].displayName}:{" "}
        <b>{this.props.stats[1].displayValue}</b>
      </p>
    );
  }
}
