import React, { Component } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import NewApi from "./components/NewApi.js";

class App extends Component {
  render() {
    return <NewApi platform={"pc"} />;
  }
}

export default App;
