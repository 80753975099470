import React, { useState } from "react";
import PlayerCard from "./PlayerCard";
import Header from "./Header";

const NewApi = () => {
  const [username, setUsername] = useState("");
  const [platform, setPlatform] = useState(0);
  const [legend, setLegend] = useState("");
  const [icon, setIcon] = useState("");
  const [error, setError] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [bgImage, setBgImage] = useState("");
  const [stats, setStats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetLegend = evt => {
    evt.preventDefault();
    console.log("Getting stats for " + username + " on platform " + platform);
    setIsLoading(true);
    getLegend(username, platform);
  };

  const updateUser = e => {
    setUsername(e.target.value);
    console.log("fired update user");
  };

  const updatePlatform = e => {
    setPlatform(Number(e.target.value));
    console.log("fired update platform");
  };

  const getLegend = async (name, platform) => {
    //Name Must Be Exact! Platforms: 1=XBOX 2=PSN 5=PC
    let apiKey = "7e9c52ba-a62d-43c2-8175-51bd94960df7";
    var proxyUrl = "https://cors-anywhere.herokuapp.com/";
    var url = `https://public-api.tracker.gg/v2/apex/standard/profile/${platform}/${name}`;
    if (platform === 0 || username === "") {
      setError(true);
      setIsLoading(false);
    } else {
      setError(false);
      return fetch(proxyUrl + url, {
        headers: {
          "TRN-Api-Key": apiKey
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          setLegend(responseJson.data.metadata.activeLegendName);
          setIcon(responseJson.data.segments[1].metadata.imageUrl);
          setBgImage(responseJson.data.segments[1].metadata.bgImageUrl);
          setStats(Object.entries(responseJson.data.segments[0].stats));
          setCurrentUser(name);
          setNotFound(false);
          setIsLoading(false);
        })

        .catch(error => {
          console.error(error);
          setNotFound(true);
          setIsLoading(false);
        });
    }
  };

  const parseData = stats.map(data => (
    <PlayerCard
      stats={data}
      key={data.toString()}
      currentUser={currentUser}
      legend={legend}
    />
  ));

  return (
    <div className="App-header">
      <Header />

      <form className="content-box" id="entry-form" onSubmit={handleGetLegend}>
        <div className="form-group row">
          <label htmlFor="field-username" className="col-sm-2 col-form-label">
            Username
          </label>
          <input
            type="textbox"
            name="username"
            id="field-username"
            className="form-control"
            placeholder="Username"
            onChange={updateUser}
          />
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">Platform</label>
          <select
            className="form-control"
            onChange={updatePlatform}
            name="platform"
          >
            <option value="0">Select</option>
            <option value="5">PC</option>
            <option value="1">XBOX</option>
            <option value="2">PSN</option>
          </select>
        </div>
        <button type="submit" className="btn btn-custom">
          Get Stats
        </button>
        <div className="error-field">
          {error && (
            <p className="my-5">
              Could not process request. Make sure all fields are filled out and
              try again
            </p>
          )}
          {notFound && (
            <p className="my-5">
              User not found. Please check your entry and try again.
            </p>
          )}
        </div>
      </form>
      <div className="my-5">
        {isLoading && <div className="loader "></div>}
        {legend !== "" && (
          <div
            id="user-data"
            className="player-card content-box"
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          >
            <img src={icon} width="50%" height="auto" alt="Legend Banner" />
            <i className="mt-5 disclaimer">
              These stats are being pulled from the{" "}
              <a href="https://tracker.gg">tracker.gg</a> database. Some
              categories may not be fully up to date.
            </i>

            <div className="text-left my-5">
              <h1>{currentUser.toUpperCase()}</h1>
              <p className="lead">
                Recent Legend: <b>{legend}</b>
              </p>
              {parseData}
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        <i className="disclaimer">
          This is a fan project by{" "}
          <a href="https://andrewcarneal.com">Andrew Carneal</a> and is in no
          way affiliated with EA or Respawn Entertainment.
        </i>
      </div>
    </div>
  );
};

export default NewApi;
