import React, { Component } from "react";

export default class Header extends Component {
  render() {
    return (
      <header className="App">
        <h3>Apex Legends Stat Lookup</h3>
        <p className="lead">
          Input your Apex Legends username and select your platform.
        </p>
      </header>
    );
  }
}
